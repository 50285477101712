import { $root } from '../utils/globals'

const hash = window.location.hash;

if ( hash.length != 0 ) {
	const dataID    = hash.replace('#', '');
	const $selector = $(`*[data-id="${dataID}"]`);

	if ($selector.length > 0) {
		$root.animate({
			scrollTop: $selector.offset().top
		}, 1000);
	};
}

$('.nav li a').on('click', function(){
	const dataID    = $(this).prop('hash').replace('#', '');
	const $selector = $(`*[data-id="${dataID}"]`);

	if ($selector.length > 0) {
		$root.animate({
			scrollTop: $selector.offset().top
		}, 1000);
	};
});


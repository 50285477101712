import 'magnific-popup';

$('.wpml-ls-link').on('click', function(){
	let currentDate = new Date();
	const cooldown  = $(this).find('.landing-popup').data('cooldown');

	currentDate.setTime(currentDate.getTime() + 3600 * 1000 * cooldown);

	document.cookie = 'popup_cooldown=yes; expires=' + currentDate.toUTCString() + '; path=/'
});


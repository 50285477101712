/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/resources-popup';

import './modules/landing-popup';

import './modules/scroll-to-section';

import 'magnific-popup';

$('.js-popup-play').on('click', function(event){
	event.preventDefault();

	const videoLink = $(this).attr('href');
	const url = window.location.href;
	const postID = $(this).data('post-id');

	$.magnificPopup.open({
	  items: {
	      src:  videoLink,
	      type: 'iframe'
	  }
	});

	$.ajax({
	  url: url,
	  type: 'GET',
	  data: {
	  	crb_post_id : postID,
	  	crb_video_url : videoLink
	  },
	});
	
});
